<template>
  <div class="guide">
    <!-- <div class="title">
      <img class="goleft" src="../assets/1.png" alt="">
      <span>准备开始</span>
    </div> -->
    <div class="" v-show="!dialogVisible">
      <div class="step">
        <span class="num">0{{step+1}}</span>
        <span class="count">/{{detailList.length || 3}}</span>
      </div>
      <div class="content">
        <div class="pos">
          <div class="line"></div>
          <img src="../assets/3.png" alt="">
        </div>
        <div class="title">{{detail.title}}</div>
        <div class="line_1"></div>
        <div class="text" ref="text"></div>
      </div>
      <div class="content_1"></div>
      <div class="content_2"></div>
      <img class="btn" src="../assets/2.png" alt="" @click="next">
    </div>
  </div>
</template>

<script>
import { saveUserInfo, getSubjectPage, getSubjectAnswerPage } from '@/api'
export default {
  name: 'my-home',
  data() {
    return {
      dialogVisible: true,
      showPicker: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      columnsType: ['year', 'month', 'day'],
      nowDate: [],
      name: '',
      are: '',
      phone: '',
      birthday: '',
      detailList: [
        {
          title: '深度认识自我',
          text: '为了让测评结果尽可能接近最真实的你接下来我需要帮你做一个心态调节首先这不是一场智力考试也不是绩效考核测评只是在帮助你更深层次地了解自己发现自己的优势测评结果没有好坏之分。'
        },
        {
          title: '凭感觉作答',
          text: '每个问题都没有标准答案在选的时候不要有心里负担只需要根据自己喜欢的偏好去选择不需要考虑选择哪个答案比较得体更不需要带着别人的期待去选择。'
        },
        {
          title: '保持放松心情',
          text: '尽可能的处于舒适、轻松的环境下例如结束了一天繁忙的工作窝在沙发里休息没有外部环境的压力找到自己最舒适放松的那个状态。'
        }
      ],
      detail: {},
      k: 0,
      text: '',
      element: null,
      index: 0,
      isOver: false,
      step: 0,
    }
  },
  created() {
    if (!localStorage.getItem('userId')) {
      this.$router.push('/login')
    }
    const jsonString = localStorage.getItem('attentionNotes')
    if (jsonString !== 'undefined' && JSON.parse(jsonString)) {
      const arr = JSON.parse(jsonString.replace(/\\\\n/g, '\\n'))
      this.detailList = []
      arr.forEach(item => {
        this.detailList.push({
          title: item.title,
          text: item.content,
        })
      });
    }
  },
  mounted() {
    this.element = this.$refs.text
    this.getSubjectPage()
    if (!localStorage.getItem('userId')) {
      this.$router.push('/login')
    }
  },
  methods: {
    getSubjectPage () {
      // getSubjectPage({pageNo: 1, pageSize: 10}).then(res => {
      //   this.detailList = res.data.list
      // })
      this.detail = this.detailList[this.step]
      this.text = this.detail.text
      this.dialogVisible = false
      this.typeWriter();
    },
    next() {
      this.step++
      if (this.step == this.detailList.length) {
        this.$router.push('/testPaper')
      } else {
        this.detail = this.detailList[this.step]
        this.text = this.detail.text; // 更新文本
        this.element.innerHTML = ''; // 清除现有内容
        this.index = 0; // 重置索引
        this.isOver = false; // 重置完成状态
        this.typeWriter();
      }
    },
    typeWriter(callback) {
      if (this.index < this.text.length) {
        let char = this.text.charAt(this.index);
        if (char === '\n') {
          char = '<br>';
        }
        this.element.innerHTML += char;
        this.index++;
        setTimeout(() => this.typeWriter(callback), 20);
      } else {
        this.isOver = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.guide {
  width: 750rem;
  min-height: 100vh;
  background: #6dc1c1;
}
.title {
  color: #ffffff;
  font-weight: 600;
  font-size: 32rem;
  line-height: 64rem;
  text-align: center;
  padding: 40rem 0;
  position: relative;
  .goleft {
    position: absolute;
    top: 40rem;
    left: 40rem;
    width: 64rem;
    height: 64rem;
  }
}
.step {
  padding: 40rem;
  font-family: 'SourceHanSerifCN';
}
.num {
  color: #ffffff;
  font-weight: 600;
  font-size: 76rem;
}
.count {
  color: #ffffff;
  font-size: 36rem;
}
.content {
  width: 670rem;
  // height: 1000rem;
  border-radius: 20rem;
  border: 2rem solid #ffffff;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0rem 4rem 24rem #399f9f33;
  margin: 0 40rem;
  position: relative;
  padding: 60rem;
  .title {
    color: #666666;

    font-weight: 600;
    font-size: 48rem;
    line-height: 48rem;
    text-align-last: left;
    padding: 0;
  }
  .line_1 {
    margin: 50rem 0 40rem;
    width: 120rem;
    height: 4rem;
    background: #86cdc6;
  }
  .text {
    color: #666666;
    min-height: 600rem;
    font-size: 32rem;
    line-height: 100rem;
  }

  .pos {
    position: absolute;
    top: 40rem;
    right: 40rem;
    .line {
      position: absolute;
      top: -70rem;
      left: 50%;
      transform: translateX(-50%);
      width: 16rem;
      height: 116rem;
      border-radius: 6rem;
      background: #edf6f6;
      box-shadow: 0rem 0rem 8rem #61999433;
    }
    img {
      width: 80rem;
      height: 80rem;
    }
  }
}
.content_1 {
  width: 620rem;
  height: 24rem;
  border-radius: 0rem 0rem 16rem 16rem;
  background: #ffffff99;
  box-shadow: 0rem 4rem 16rem #299e9e2e;
  margin: 0 64rem;
}
.content_2 {
  width: 560rem;
  height: 24rem;
  border-radius: 0rem 0rem 16rem 16rem;
  background: #ffffff4d;
  box-shadow: 0rem 4rem 16rem #299e9e1a;
  margin: 0 94rem;
}
.btn {
  width: 160rem;
  height: 80rem;
  margin: 80rem 294rem;
}
::v-deep .van-popup--center {
  min-width: 750rem;
}
::v-deep .van-dialog {
  height: 100vh;
  border-radius: 0;
  background: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  top: 0;
  transform: translateY(0);
  .van-dialog__title {
    color: #333333;
    font-weight: 500;
    font-size: 32rem;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  .str {
    text-align: center;
    color: #666666;
    font-size: 28rem;
    line-height: 28rem;
    margin: 50rem 0;
  }
  .van-cell {
    padding-right: 40rem;
    padding-left: 40rem;
  }
  .btn {
    width: 160rem;
    height: 80rem;
    margin: 20rem 0 40rem;
  }
}
::v-deep .van-radio__icon--checked .van-icon {
  color: #c2e5e5;
  background-color: #6dc1c1;
  border-color: #6dc1c1;
}
</style>