<template>
  <div class="page">
    <div class="titel">标准分</div>
    <table>
        <thead>
            <tr>
                <th>项目</th>
                <th>标分</th>
                <th>项目</th>
                <th>标分</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in tableList" :key="index">
              <td >{{item[0].title}}</td>
              <td>
                {{item[0].value}}
                <span v-if="item[0].value>=8">(高分)</span>
                <span v-if="item[0].value<=3">(低分)</span>
              </td>
              <td>{{item[1].title}}</td>
              <td>
                {{item[1].value}}
                <span v-if="item[1].value>=8">(高分)</span>
                <span v-if="item[1].value<=3">(低分)</span>
              </td>
            </tr>
        </tbody>
    </table>
    <div class="titel">二元分析结果</div>
    <table>
        <thead>
            <tr>
                <th>项目</th>
                <th>标分</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, index) in dualList" :key="index">
              <td >{{item.title}}</td>
              <td>
                {{item.value}}
                <span v-if="item.value>8">(高分)</span>
                <span v-if="item.value<3">(低分)</span>
              </td>
            </tr>
        </tbody>
    </table>
    <div class="titel">标准分结果</div>
    <div class="description">使用标准分 0-10，小于等于 3 分为低分，大于等于 8 分为高分。</div>
    <div class="detail" v-for="(item, index) in standarList" :key="index">
      <div class="titel">{{item.key}}：{{item.title}}</div>
      <div class="score">
        <div>{{item.value}}</div>
        <div class="value_line"></div>
        <div class="line_stpe">
          <div class="line_box" v-for="item in 10" :key="item">
            <div class="box"></div>
            <div class="line">
              <span v-if="item%2===0">{{item}}</span>
            </div>
          </div>
          <div class="color_line">
            <div class="color_line_item" :class="item.value>=0 && 'c_0'" style="width: 30%;">低分</div>
            <div class="color_line_item" :class="item.value>=3 && 'c_1'" style="width: 40%;">中等</div>
            <div class="color_line_item" :class="item.value>=8 && 'c_2'" style="width: 30%;">高分</div>
          </div>
        </div>
      </div>
      <div>{{item.low}}</div>
      <div>{{item.high}}</div>
      <div>{{item.description}}</div>
    </div>
    <div class="titel">二元分析结果</div>
    <div class="detail" v-for="(item, index) in dualList" :key="index">
      <div class="titel">{{item.key}}：{{item.title}}</div>
      <div class="score">
        <div>{{item.value}}</div>
        <div class="value_line"></div>
        <div class="line_stpe">
          <div class="line_box" v-for="k in item.totalScore" :key="k">
            <div class="box"></div>
            <div class="line" v-if="item.totalScore < 20">
              <span v-if="k%2===0">{{k}}</span>
            </div>
            <div class="line" v-else-if="item.totalScore < 50">
              <span v-if="k%5===0">{{k}}</span>
            </div>
            <div class="line" v-else>
              <span v-if="k%20===0">{{k}}</span>
            </div>
          </div>
          <div class="color_line">
            <div
              v-for="(j, i) in item.scoreArr" 
              :key="i"
              class="color_line_item"
              :class="item.value>=j.num && `c_${i}`" 
              :style="`width: ${j.width};`">
             {{j.name}}
            </div>
          </div>
        </div>
      </div>
      <div>{{item.low}}</div>
      <div>{{item.high}}</div>
      <div>{{item.description}}</div>
    </div>
  </div>
</template>

<script>
import { getResults } from '@/api'
import * as regData from './data'
import { showToast } from 'vant';

export default {
  name: 'cattell_16_PF',
  data() {
    return {
      rawObj: {}, // 原始分
      standardObj: {}, // 标准分
      occObj: {}, // 职业规划
      standarList: [],
      tableList : [],
      dualList : [],
    }
  },
  created() {
    if (!localStorage.getItem('userId')) {
      this.$router.push('/login')
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList () {
      getResults({
        userId: localStorage.getItem('userId'),
        evaluationId: 67,
        subjectId: 80
      }).then(res => {
        this.list = res.data
        if (this.list.length !== 187) {
          showToast('抱歉，您没有该测评的填写记录，请完成测评再查看结果')
          this.$router.go(-1)
        } else {
          this.getRawList()
        }
      })
    },
    getRawList () {
      const resultObj = {}
      this.list.forEach(item => {
        for (const key in regData.rawScore) {
          if (regData.rawScore[key].includes(item.number)) {
            if (!resultObj[key]) {
              resultObj[key] = 0;
            }
            resultObj[key] += parseInt(item.sortContent, 10);
          }
        }
      });
      this.rawObj = resultObj;
      this.getStandardList()
      this.getFinalScore()
    },
    getStandardList () {
      function findRange(value, ranges) {
        for (let key in ranges) {
          let range = ranges[key];
          let [min, max] = range.split('-').map(Number);
          if (max === undefined) max = min;
          if (value >= min && value <= max) {
            return Number(key);
          }
        }
        return null;
      }
      let obj3 = {};
      for (let key in this.rawObj) {
        if (regData.standardScore[key]) {
          obj3[key] = findRange(this.rawObj[key], regData.standardScore[key]);
        }
      }
      this.standardObj = obj3;
      console.log(obj3)
      this.standarList = this.generateArray(obj3, regData.finalObj)
      this.tableList = this.convertTo2DArray(this.generateArray(obj3, regData.finalObj))
    },
    getFinalScore () {
      const obj = this.rawObj
      const scoreObj = {
          X1: regData.X1(obj),
          X2: regData.X2(obj),
          X3: regData.X3(obj),
          X4: regData.X4(obj),
          Y1: regData.Y1(obj),
          Y2: regData.Y2(obj),
          Y3: regData.Y3(this.standardObj),
          Y4: regData.Y4(obj),
      }
      this.occObj = scoreObj
      this.dualList = this.generateArray(scoreObj, regData.occObj)
      console.log(this.dualList)
    },
    generateArray(objA, objB) {
      let resultArray = [];
      let keys = Object.keys(objB);  // 获取 objA 的键的顺序
      keys.forEach(key => {
          if (Object.prototype.hasOwnProperty.call(objB, key)) {
              let newItem = {
                  ...objB[key],
                  title: objB[key].title,
                  key: key,
                  value: objA[key],
              };
              resultArray.push(newItem);
          }
      });
      return resultArray
    },
    convertTo2DArray(arr) {
      let result = [];
      let mid = arr.length / 2;
      for (let i = 0; i < mid; i++) {
          result.push([arr[i], arr[i + mid]]);
      }
      return result;
    }

  }
}
</script>

<style scoped lang="scss">
.page {
  width: 750rem;
  min-height: 100vh;
  background: #edf6f6;
  padding-bottom: 140rem;
  padding: 30rem;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 30rem;
}
th, td {
  border: 2rem solid #ccc;
  padding: 15rem;
  text-align: left;
}

.titel {
  font-size: 48rem;
  font-weight: 500;
  margin: 20rem 0;
}
.description {
  font-size: 32rem;
}

.detail {
  font-size: 34rem;
  margin-bottom: 30rem;
  line-height: 68rem;
  .titel {
    font-size: 36rem;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10rem;
    border-bottom: 2rem solid #bbb;
  }
}
.score {
  display: flex;
  align-items: center;
  font-size: 28rem;
  padding: 50rem 20rem;
  .value_line {
    width: 10rem;
    height: 70rem;
    border-bottom: 2rem solid #333;
    border-right: 2rem solid #333;
  }
}
.line_stpe {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  .line_box {
    flex: 1;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    .box {
      flex: 1;
      height: 40rem;
      background-color: #ccc;
    }
    .line {
      width: 2rem;
      height: 60rem;
      background-color: #ccc;
      position: relative;
      font-size: 24rem;
      color: #ccc;
      span {
        position: absolute;
        top: 70rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .color_line {
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 0;
    font-size: 24rem;
    line-height: 40rem;
    text-align: center;
    padding: 10rem 0;
    .color_line_item {
      height: 40rem;
    }
    .c_0 {
      background-color: #bfdbfe;
    }
    .c_1 {
      background-color: #69b5ff;
    }
    .c_2 {
      background-color: #2563eb;
    }
    .c_3 {
      background-color: #1e41b5;
    }
    .c_4 {
      background-color: #1e3a8a;
    }
  }
}

</style>