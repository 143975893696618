<template>
  <div class="page">
    <img class="pic" :src="detail.icon" alt="">
    <div class="detail">
      <div class="title">{{detail.title}}</div>
      <div class="title_en">{{detail.sortContent}}</div>
      <div class="text">{{detail.content}}</div>
      <div class="title_son">测评须知：</div>
      <div class="info">
        <div class="info_text">
          <img class="icon" src="../assets/19.png" alt="">
          阶段：共 {{detail.subjectCount}} 阶段
        </div>
        <div class="info_text">
          <img class="icon" src="../assets/20.png" alt="">
          题目：总计 {{detail.questionsCount}} 道题
        </div>
        <div class="info_text">
          <img class="icon" src="../assets/21.png" alt="">
          时间：预计 {{detail.retime}} 分钟
        </div>
      </div>

      <div class="title_son">测评后您将获得：</div>
      <div class="card_box">
        <div class="card" @click="goPage()">
          <img src="../assets/24.png" alt="">
          测评报告
        </div>
        <div class="card" @click="goPage()">
          <img src="../assets/25.png" alt="">
          专业解读
        </div>
      </div>
      <div class="btn" @click="geTest">开始测评</div>
    </div>
  </div>
</template>

<script>
import { getSubjectRenwuGet } from '@/api'
import { toChineseNum } from '@/utils/data'
import { showToast } from 'vant';
export default {
  name: 'detail-view',
  data() {
    return {
      id: '',
      detail: {},
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  mounted() {
    this.getData()
  },
  filters: {
    // 在组件内定义过滤器
    toChineseNum(value) {
      return toChineseNum(value);
    }
  },
  methods: {
    getData () {
      getSubjectRenwuGet({id: this.id}).then(res => {
        this.detail = res.data
        this.detail.subjectCount = toChineseNum(this.detail.subjectCount)
      })
    },
    geTest () {
      localStorage.setItem('stageList', JSON.stringify(this.detail.subjectList))
      localStorage.setItem('attentionNotes', this.detail.attentionNotes)
      localStorage.setItem('typeId', this.detail.id)
      this.$router.push('/HomeView')
    },
    goPage () {
      if (this.id == 62) {
        this.$router.push('/cattell16PF')
      } else {
        showToast('功能开发中!');
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  width: 750rem;
  min-height: 100vh;
  background: #edf6f6;
  padding-bottom: 140rem;
}
.detail {
  padding: 40rem;
}
.pic {
  width: 750rem;
  height: 370rem;
  border-radius: 10rem 10rem 0rem 0rem;
  object-fit: cover;
}
.title {
  color: #333333;
  font-family: Alimama ShuHeiTi;
  font-weight: bold;
  font-size: 48rem;
  line-height: 48rem;
}
.title_en {
  transform: scale(1, 0.984808) skew(-9.851075deg, 0deg);
  color: #cccccc;
  font-size: 24rem;
  line-height: 24rem;
  text-transform: capitalize;
  padding: 20rem 0 25rem;
}
.text {
  color: #666666;
  font-size: 28rem;
  line-height: 42rem;
}
.title_son {
  color: #333333;
  font-weight: 500;
  font-size: 32rem;
  line-height: 32rem;
  padding: 30rem 0;
}
.info {
  width: 670rem;
  border-radius: 20rem;
  background: #ffffff4d;
  padding: 30rem;
  .info_text {
    color: #666666;
    font-size: 28rem;
    line-height: 28rem;
    margin-bottom: 40rem;
    display: flex;
    align-items: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .icon {
    margin-right: 10rem;
    width: 28rem;
    height: 28rem;
  }
}
.card_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .card {
    width: 320rem;
    height: 240rem;
    border-radius: 20rem;
    background: #ffffff59;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666666;
    font-size: 28rem;
    line-height: 28rem;
    img {
      width: 80rem;
      height: 80rem;
      margin-bottom: 30rem;
    }
  }
}
.btn {
  position: fixed;
  bottom: 40rem;
  left: 50%;
  transform: translateX(-50%);
  width: 670rem;
  height: 80rem;
  border-radius: 20rem;
  background: #6dc1c1;
  color: #ffffff;
  font-size: 28rem;
  line-height: 80rem;
  text-align: center;
}
</style>