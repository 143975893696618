<template>
  <div class="paper" ref="paper" :class="paperStyle" >
    <div class="progress_box">
      <span style="width: 130rem;">{{on}}/{{total}} 题</span>
      <van-progress :percentage="progressNum" :show-pivot="false" color="#6dc1c1" />
      <span style="width: 80rem;">{{displayTime}}</span>
      <img class="icon" src="../assets/16.png" alt="">
    </div>
    <div class="paper_list" ref="paper_list" :style="'height: '+paperListHeight+'px'">
      <div class="loading_box" v-show="loading">
        <div class="rectangle_box">
          <div class="rectangle" v-for="item in 30" :key="item"></div>
        </div>
        <div class="eyes_1 eyes"></div>
        <div class="eyes_2 eyes"></div>
      </div>
      <div class="item" v-for="(item, index) in list" :key="index" :ref="'item'+index" :id="'item'+index" :style="'max-height: '+paperListHeight+'px;top:'+(paperListHeight + 140 * rem)+'px'">
        <div class="item_label">
          <span class="index">{{item.sort}}.</span><span class="question">{{item.title}}</span>
        </div>
        <img width="100%" v-if="item.icon" :src="item.icon" alt="" @load="handleImageLoad">
        <van-radio-group :class="item.icon && 'img_group'"  v-model="item.questionsInfo" shape="square">
          <van-radio
            label-position="left"
            :name="k.title"
            :label="k.content"
            v-for="(k, i) in item.questionsInfoDOList"
            :key="i"
            :class="item.questionsInfo == k.title && 'on'"
            @click="radioChange(item, 'item'+index)">
            <span>{{k.title}}</span>
            <span v-if="k.content">.{{k.content}}</span>
          </van-radio>
        </van-radio-group>
      </div>
    </div>

    <div class="footer">
      <img class="left" src="../assets/5.png" @click="throttlePrevious">
      <img class="stop" src="../assets/6.png" @click="stop">
      <img class="right" src="../assets/7.png" @click="throttleNext">
    </div>

    <van-dialog v-model:show="show" title="答题辛苦了～" :showConfirmButton="false">
      <div class="str">转转脖子歇一歇，保持轻松与轻快的答题节奏，下一阶段15分钟即可完成</div>
      <img class="btn" src="../assets/13.png" alt="" @click="getstage">
    </van-dialog>

    <van-dialog v-model:show="show2" title="您尚有答题未完成" :showConfirmButton="false">
      <div class="str">您已经参与测评且尚未完成，是否需要继续答题，或重新开始。</div>
      <div class="btn_box">
        <img src="../assets/14.png" alt="" style="margin-right: 30rem;">
        <img src="../assets/15.png" alt="">
      </div>
    </van-dialog>

    <van-dialog v-model:show="showStop" title="答题暂停" :showConfirmButton="false">
      <img class="stop" src="../assets/17.png" alt="">
      <div class="str">转转脖子歇一歇，保持轻松与轻快的答题节奏，休息结束后随时开始答题</div>
      <img class="btn" src="../assets/13.png" alt="" @click="startTimer">
    </van-dialog>


  </div>
</template>

<script>
import {
  getQuestionsPage,
  evaluationValueCreate,
  evaluationLink,
  getNewquestions,
  evaluationValueRun,
  evaluationValuetover,
  evaluationValueOver
} from '@/api'
import { toChineseNum } from '@/utils/data'
import { Empty, showToast } from 'vant';
import { throttle, isEmpty, debounce } from 'lodash';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
export default {
  name: 'test-paper',
  data() {
    return {
      loading: false,
      paperStyle: 'paper_2',
      rem: 1,
      progressNum: 0,
      paperListHeight: 0,
      on: 0,
      list: [],
      total: 0,
      show: false,
      show2: false,
      showStop: false,
      count: 0, // 已使用时间 秒
      displayTime: '00:00',
      intervalId: null,
      stageList: [],
      stageNum: 0,
      stageName: '',
      steps: [],
      time: 0, // 本阶段限时
      type: 1,
      jumpList: [],
      jumpIndex: 0,
      evaluationId: '',
      oldData: {},
      isOnePage: false,
    }
  },
  created() {
    this.stageList = JSON.parse(localStorage.getItem('stageList'))
    this.stageNum = localStorage.getItem('stageNum') || 0
    this.evaluationId = localStorage.getItem('evaluationId')
    if (!localStorage.getItem('userId')) {
      this.$router.push('/login')
    }
  },
  beforeUnmount () {
    this.stopTimer();
  },
  mounted() {
    this.getNew()
    const dom = document.getElementsByClassName('rectangle')
    for (const [index, item] of Array.from(dom).entries()) {
      item.style.transform = 'rotate('+(index*2)+'deg)';
      item.style.opacity = index*0.04;
    }
  },
  methods: {
    getNew () {
      this.loading = true
      getNewquestions({
        evaluationId: this.evaluationId,
        userId: localStorage.getItem('userId'),
        typeId: localStorage.getItem('typeId'),
        }).then(res => {
        this.oldData = res.data || {}
        if (!isEmpty(this.oldData) && !isEmpty(this.oldData.answerStatus)) {
          this.stageNum = this.stageList.findIndex(k => {
            return k.id == this.oldData.subjectId
          })
          localStorage.setItem('stageNum', this.stageNum)
        }
        this.getstage()
      })
    },
    init () {
      this.on = 0
      this.list = []
      this.show = false
      clearInterval(this.intervalId);
      this.getList()
    },
    getstage () {
      this.loading = true
      this.subjectId = this.stageList[this.stageNum].id
      this.time = this.stageList[this.stageNum].time
      this.type = this.stageList[this.stageNum].type
      this.paperStyle = this.type==3 ? 'paper_2' : ''
      this.stageName = this.stageList[this.stageNum].title
      document.title = this.stageName
      if (this.type == 4) {
        this.$router.push('/testPaperPic')
      }
      this.$nextTick(() => {
        this.init()
        if (this.stageList[this.stageNum].sortContent) {
          this.steps = [
            {
                element: '#item0',
                popover: {
                  description:  this.stageList[this.stageNum].sortContent,
                  side: 'center'
                }
            }
          ]
        }
      })
    },
    guide() {
      const driverObj = driver({
        steps: this.steps
      });
      driverObj.drive();
    },
    getList () {
      const data = {
        pageNo: 1,
        // pageSize: 999,
        subjectId: this.subjectId,
        evaluationId: this.evaluationId,
        userId: localStorage.getItem('userId')
      }
      getQuestionsPage(data).then(res => {
        this.list = res.data.list
        this.total = res.data.total
        this.$nextTick(_ => {
          this.getDomHeight()
          this.startTimer();
        })
      })
    },
    debounceStageChange: debounce(function() {
      this.stageChange();
    }, 500),
    stageChange () {
      this.stageNum++
      // this.init()
      if (isEmpty(this.stageList[this.stageNum])) {
        this.$router.push('/reportView')
        return
      }
      localStorage.setItem('stageNum', this.stageNum)
      // this.stageName = this.stageList[this.stageNum]
      this.subjectId = this.stageList[this.stageNum].id
      this.show = true
      evaluationValueOver({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')})
    },
    previous: debounce(function() {
      this.previousDebounce();
    }, 500),
    previousDebounce () {
      if (this.isOnePage) return
      this.isOnePage = false // 是否在首页
      // 初始化上一页的页首索引
      let prevPageIndex = this.on - 1
       for (let i = 0; i < prevPageIndex+1; i++) {
        const previousDomm = this.$refs['item' + i][0];
        previousDomm.style.transform = 'translateY(0)';
      }
      // 检查当前项是否为页首
      if (this.list[prevPageIndex].translateY === this.paperListHeight) {
        // 当前项就是页首，向前找上一页的页首
        for (let i = prevPageIndex - 1; i >= 0; i--) {
          if (this.list[i].translateY === this.paperListHeight) {
            prevPageIndex = i;
            break;
          }
        }
      } else {
        // 当前项不是页首，先找到本页的页首
        for (let i = prevPageIndex; i >= 0; i--) {
          if (this.list[i].translateY === this.paperListHeight) {
            // 找到本页的页首后，继续向前找上一页的页首
            for (let j = i - 1; j >= 0; j--) {
              if (this.list[j].translateY === this.paperListHeight) {
                this.isOnePage = j == 0
                prevPageIndex = j;
                break;
              }
            }
            break;
          }
        }
      }
      // 实际项目中可能需要执行更复杂的操作，比如动画等
      this.on = prevPageIndex;
      this.$nextTick(() => {
        this.next()
      })
    },
    stop () {
      this.stopTimer()
    },
    throttlePrevious: throttle(function() {
      if (this.type==3) {
        this.jumpPre()
      } else {
        this.previous();
      }
    }, 1000),
    throttleNext: throttle(function() {
      if (this.type==3) {
        this.jumpNext()
      } else {
        this.isNext(0, 1);
      }
    }, 1000),
    next: debounce(function() {
      this.loading = false
      this.debounceNext();
    }, 500),
    debounceNext () {
      if (this.list.length && this.on >= this.list.length) {
        if (this.list[this.on-1].questionsInfo && this.list[this.on-2].questionsInfo) {
          this.debounceStageChange()
        }
        return
      }
      // 检查当前项是否为页首
      if (this.list[this.on].translateY !== this.paperListHeight) {
        for (let i = this.on; i >= 0; i--) {
          if (this.list[i].translateY === this.paperListHeight) {
            // 找到本页的页首后，继续向前找上一页的页首
            for (let j = i; j >= 0; j--) {
              if (this.list[j].translateY === this.paperListHeight) {
                this.isOnePage = j == 0
                break;
              }
            }
            break;
          }
        }
      }
      if (this.list[this.on].translateY === this.paperListHeight) {
        for (let i = 0; i < this.on; i++) {
          const previousDomm = this.$refs['item' + i][0];
          previousDomm.style.transform = 'translateY(0)';
          previousDomm.style.transition = 'none';
          setTimeout(_ => {
            previousDomm.style.transition = 'transform 1s ease';
          }, 1000)
        }
      }
      setTimeout(() => {
        const dom = this.$refs['item' + this.on][0];
        dom.style.transform = 'translateY(-' + (this.list[this.on].translateY + (140*this.rem)) + 'px)';
        this.on++
        this.progressNum = this.on / this.list.length * 100
        if (this.on < this.list.length && this.list[this.on].translateY !== this.paperListHeight) {
          this.next()
        }
      }, 200)
    },
    calculateTranslateY(list) {
      let accumulatedHeight = 0;  // 累计高度
      for (let i = 0; i < list.length; i++) {
        // 如果当前项的domHeight加上累计的高度小于等于paperListHeight
        if (accumulatedHeight + list[i].domHeight <= this.paperListHeight) {
          accumulatedHeight += list[i].domHeight;
          list[i].translateY = this.paperListHeight - accumulatedHeight + list[i].domHeight;
        } else {
          // 如果当前项的domHeight加上累计的高度大于paperListHeight
          list[i].translateY = this.paperListHeight;
          accumulatedHeight = list[i].domHeight;  // 重置累计高度为当前项的domHeight
        }
      }
      this.list = list
      this.$nextTick(_ => {
        if (!isEmpty(this.oldData) && !isEmpty(this.oldData.answerStatus)) {
          const item = this.oldData.answerStatus
          const obj = this.list.find(k => {
            return k.id == item.questionsId
          })
          if (obj) {
            // 初始化this.on为找到的obj的索引
            this.on = this.list.indexOf(obj);
          }
          // 往前找，找到translateY等于this.paperListHeight的那一项的index
          for (let i = this.on; i >= 0; i--) {
            if (this.list[i].translateY === this.paperListHeight) {
              this.on = i;
              break; // 找到后退出循环
            }
          }
          // this.on = obj.translateY === this.paperListHeight ? obj.number - 1 : obj.number-2
          if (!obj || this.on >= this.list.length-1) {
            this.stageNum++
            localStorage.setItem('stageNum', this.stageNum)
            this.getstage()
          }
        } else {
          this.on = 0
        }
      })
    },
    getDomHeight() {
      const that = this
      that.rem = document.documentElement.style.fontSize.slice(0, -2)
      that.paperListHeight = window.innerHeight - (265)*that.rem
      that.list.forEach((item, index) => {
        item.sort = (index+1) < 10 ? '0' + (index+1) : (index+1).toString();
      })
      let imgList = []
      that.$nextTick(() => {
        that.list.forEach((item, index) => {
          imgList.push(item.icon)
          const refName = `item${index}`;
          const itemElement = that.$refs[refName];
          if (itemElement) {
            // 根据type值调整元素高度
            let height;
            if (that.type == 1) {
              // 在type为1时，可以根据实际内容自适应高度，或者设定一个更小的固定高度
              height = itemElement[0].clientHeight;
            } else if (that.type == 3) {
              height = that.paperListHeight;
            } else {
              height = itemElement[0].offsetHeight;
            }
            item.domHeight = height;
            itemElement[0].style.top = that.paperListHeight + 140*that.rem + 'px';
          }
        });
        that.calculateTranslateY(that.list)
        imgList = imgList.filter(Boolean);
        if (!imgList.length) {
          setTimeout(() => {
            that.next()
          }, 500)
        }
      });
    },
    handleImageLoad: debounce(function() {
      console.log('有图片加载完成 重新计算高度')
      this.list.forEach((item, index) => {
        const refName = `item${index}`;
        const itemElement = this.$refs[refName];
        if (itemElement) {
            // 根据type值调整元素高度
            let height;
            if (this.type == 1) {
              // 在type为1时，可以根据实际内容自适应高度，或者设定一个更小的固定高度
              height = itemElement[0].clientHeight;
            } else if (this.type == 3) {
              height = this.paperListHeight;
            } else {
              height = itemElement[0].offsetHeight;
            }
            item.domHeight = height;
            itemElement[0].style.top = this.paperListHeight + 140*this.rem + 'px';
          }
      });
      this.calculateTranslateY(this.list)
      this.$nextTick(() => {
        this.next()
      });
    }, 1000),
  
    radioChange (row, ref) {
      // 处理莫名其妙的跳转错误 增加判断该元素必须是弹出后的才能正常选择答案
      let transformValue = this.$refs[ref][0].style.transform;
      let matches = transformValue.match(/translateY\((-?\d+)px\)/);
      if (matches && parseInt(matches[1], 10) === 0) {
        console.log("translateY的值为0");
        return
      }
      let data = {
        userId: localStorage.getItem('userId'),
        evaluationId: this.evaluationId, //活动id
        questionsId: row.id, // 题目id
        subjectId: row.subjectId, // 阶段id
        number: row.number,
        content: row.questionsInfo,
        status: 0,
        type: 1,
      }
      if (this.type !=3) {
        evaluationValueCreate(data).then(res => {
          this.isNext(Number(row.sort))
        })
      } else {
        const obj = row.questionsInfoDOList.find(item => {
            return item.title == row.questionsInfo
        })
        if (obj.number == 9999) {
          data.content = obj.title
          this.on = this.list.length
          evaluationValueCreate(data).then(res => {
            this.debounceStageChange()
          })
        } else {
          this.jumpList.push(row)
          this.jumpIndex++
          this.on = obj.number - 1
          this.next()
        }
      }
    },
    jumpPre () {
      if (this.jumpIndex == 0) return
      for (let i = 0; i < this.on+1; i++) {
        const previousDomm = this.$refs['item' + i][0];
        previousDomm.style.transform = 'translateY(0)';
      }
      const obj = this.jumpList[this.jumpIndex - 1]
      this.jumpIndex--
      this.on = obj.number - 1
      this.next()
    },
    jumpNext () {
      if (!this.jumpIndex && this.jumpIndex > this.jumpIndex.length) return
      let obj = {}
      if (this.jumpIndex == this.jumpList.length-1) {
        obj = this.jumpList[this.jumpIndex].questionsInfoDOList.find(item => {
            return item.title == this.jumpList[this.jumpIndex].questionsInfo
        })
      } else {
        obj = this.jumpList[this.jumpIndex + 1]
      }
      this.jumpIndex++
      this.on = obj.number - 1
      this.next()
    },
    isNext (i, type) {
      const index = this.on
      const lastItem = this.list[i]
      var isQuestionsInfo = true
      for (let i = 0; i < index; i++) {
        if (!this.list[i].questionsInfo) {
          isQuestionsInfo = false
        }
      }
      if (lastItem && lastItem.translateY == this.paperListHeight && isQuestionsInfo) {
        this.next()
      } else if (type==1 && !isQuestionsInfo) {
        showToast('当前页面还有题目未完成哦!');
      } else if (!lastItem) {
        this.stageChange()
      }
    },

    startTimer() {
      evaluationValueRun({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')}).then(res => {
        this.count = Number(res.data.time)
        this.showStop = false
        this.intervalId = setInterval(() => {
          this.count++;
          let minutes = Math.floor(this.count / 60);
          let seconds = this.count % 60;
          this.displayTime = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
          if (this.count % 5 === 0) {
            evaluationLink({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')})
          }
        }, 1000);
      })
    },
    stopTimer() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.showStop = true
        evaluationValuetover({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')})
      }
    }

  }
}
</script>

<style scoped lang="scss">
.paper {
  width: 750rem;
  height: calc(100vh - 140rem);
  background: #edf6f6;
  padding-bottom: 140rem;
  overflow: hidden;
}
.title {
  color: #333333;
  font-weight: 600;
  font-size: 32rem;
  text-align: center;
  line-height: 64rem;
  padding-top: 40rem;
  position: relative;
  .goleft {
    position: absolute;
    top: 40rem;
    left: 40rem;
    width: 64rem;
    height: 64rem;
  }
}
.progress_box {
  display: flex;
  align-items: center;
  padding: 0 30rem;
  margin: 40rem 0 50rem;
  span {
    display: inline-block;
    height: 32rem;
    color: #000000;
    font-size: 32rem;
    line-height: 32rem;
  }
  .van-progress {
    flex: 1;
    height: 12rem;
    border-radius: 6rem;
    background: #c7e7e7;
    margin: 0 26rem;
  }
  .icon {
    width: 25.5rem;
    height: 25.5rem;
    margin-left: 10rem;
  }
}
.paper_list {
  background-color: #fff;
  background: linear-gradient(to bottom, #edf6f6 0%, #ffffff 50%);
  border-radius: 40rem 40rem 0rem 0rem;
  position: relative;
  .item {
    width: 750rem;
    background: #ffffff;
    border-radius: 40rem 40rem 0rem 0rem;
    box-shadow: 0px -48rem 48rem -20rem rgba(109, 193, 193, 0.3);
    padding: 56rem 40rem 0;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(0);
    transition: transform 1s ease;
    .item_label {
      margin-bottom: 54rem;
      font-size: 32rem;
      line-height: 42rem;
      .index {
        color: #333333;
        font-family: 'SourceHanSerifCN';
        font-size: 56rem;
        line-height: 56rem;
        margin-right: 10rem;
      }
      .question {
        padding-top: 15rem;
        color: #333333;
        font-size: 32rem;
        line-height: 42rem;
        white-space: pre-wrap;
      }
    }
    .van-radio-group {
      display: block;
      .van-radio {
        display: flex;
        justify-content: space-between;
        height: auto;
        margin-bottom: 50rem;
        margin-right: 0 !important;
        color: #444444;

        font-weight: 300;
        font-size: 32rem;
        line-height: 48rem;
        ::v-deep .van-radio__icon--checked .van-icon {
          color: #c2e5e5;
          background-color: #6dc1c1;
          border-color: #6dc1c1;
        }
      }
      ::v-deep .van-radio__label {
        line-height: 48rem;
      }
      ::v-deep .van-radio__icon .van-icon {
        width: 24rem;
        height: 24rem;
        line-height: 24rem;
        font-size: .6em;
      }
    }
    .img_group {
      display: flex;
      .van-radio {
        width: 80rem;
        height: 80rem;
        border-radius: 20rem;
        border: 2rem solid rgba(0, 0, 0, 1);
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        margin-right: 30rem !important;
        justify-content: center !important;
        ::v-deep .van-radio__icon {
          display: none;
        }
        ::v-deep .van-radio__label--left {
          margin: 0;
        }
        ::v-deep .van-radio__icon--checked .van-icon {
          color: #c2e5e5;
          background-color: #6dc1c1;
          border-color: #6dc1c1;
        }
      }
      .on {
        border: 2rem solid rgba(109, 193, 193, 1);
        ::v-deep .van-radio__label {
          color: #6DC1C1;
        }
      }
    }
  }

}
.footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 750rem;
  height: 140rem;
  border-radius: 40rem 40rem 0rem 0rem;
  background: #6dc1c1;
  display: flex;
  align-items: center;
  justify-content: center;
  .left {
    width: 60rem;
    height: 60rem;
  }
  .stop {
    width: 100rem;
    height: 100rem;
    margin: 0 60rem;
  }
  .right {
    width: 60rem;
    height: 60rem;
  }
}
.paper_2 {
  background: #ffff;
  .paper_list {
    background: #ffff;
    .item {
      border-radius: 0;
      box-shadow: none;
      padding-top: 0;
    }
  }
}

::v-deep .van-dialog {
  width: 560rem;
  border-radius: 20rem;
  background: #ffffff;
  padding: 0 50rem 60rem;
  text-align: center;
  .str {
    color: #666666;

    font-size: 28rem;
    line-height: 48rem;
    margin-top: 30rem;
  }
  .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50rem 20rem 25rem;
    .icon_box {
      width: 80rem;
      height: 80rem;
      background: #edf6f6;
      border-radius: 80rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 44rem;
        height: 44rem;
      }
    }
    .on {
      background: #8acdcd;
      border: 2rem solid #6dc1c1;
    }
    .line {
      flex: 1;
      height: 4rem;
      border-radius: 4rem;
      background: #6dc1c1;
      margin: 0 10rem;
    }
  }
  .p {
    text-align: center;
    color: #333333;

    font-size: 28rem;
    line-height: 28rem;
  }
  .stop {
    margin-top: 50rem;
    width: 224rem;
    height: 236rem;
  }
  .btn {
    margin-top: 40rem;
    width: 160rem;
    height: 80rem;
  }
  .btn_box {
    margin-top: 40rem;
    img {
      width: 162rem;
      height: 82rem;
    }
  }
}

.loading_box {
  position: fixed;
  top: 45%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
  // background-color: pink;
}
.rectangle_box {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 60px;
  height: 60px;
  // background-color: pink;
  border-radius: 50%;
  // transform-origin: 30px 30px;
  transform:  rotate(0deg);
  animation: rotateAround 1s linear infinite;
}
.rectangle {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  background-color: #6dc1c1;
  border-radius: 10px;
  transform-origin: 30px 30px;
}
.eyes_1 {
  position: absolute;
  top: 15px;
  left: 10px;
  width: 10px;
  height: 30px;
  // background-color: rgba(196, 97, 97, 0.5);
}
.eyes_2 {
  position: absolute;
  top: 15px;
  right: 10px;
  width: 10px;
  height: 30px;
  // background-color: rgba(196, 97, 97, 0.5);
}
.eyes {
  width: 10px;
  height: 20px;
  border-radius: 20px;
  background-color: #6dc1c1;
  z-index: 999;
  animation: enhancedBouncing 1s cubic-bezier(0.28, 0.84, 0.42, 1) infinite;
  transition: top 0.5s ease;
}
.eyes::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 20px;
  background-color: #6dc1c1;
  animation: bounceShadow 1s cubic-bezier(0.28, 0.84, 0.42, 1) infinite;
  opacity: 0.7; /* 调整透明度以创建拖影效果 */
  z-index: -1; /* 确保拖影在元素下方 */
  transition: top 0.5s ease;
}

@keyframes enhancedBouncing {
  0%, 100% {
    top: 10px;
  }
  50% {
    top: 30px;
  }
}

@keyframes bounceShadow {
  0%, 100% {
    top: 7px;
    opacity: 0.5;
  }
  25%, 75% {
    opacity: 1;
  }
  50% {
    top: -7px;
    opacity: 0.5;
  }
}
@keyframes rotateAround {
  from {
    transform: rotate(0deg) ;
  }
  to {
    transform: rotate(360deg) ;
  }
}
</style>