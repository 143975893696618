<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" v-if="isReload" />
    </transition>
  </router-view>
</template>
<script>
import './utils/flexible.js';
export default {
  data () {
    return {
      isReload: true
    }
  },
  provide () {
    return {
      reload: this.reload,
    };
  },
  methods: {
    reload () {
      this.isReload = false
      this.$nextTick(() => {
        this.isReload = true
      })
    }
  }
}
</script>
<style lang="scss">

@font-face {
  font-family: 'SourceHanSerifCN';
  font-weight: 400;
  src: url("./assets/SourceHanSerifCN.woff2") format("woff2"),
  url("./assets/SourceHanSerifCN.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Alimama ShuHeiTi";
  font-weight: 700;
  src: url("./assets/AlimamaShuHeiTi-Bold.woff2") format("woff2"),
  url("./assets/AlimamaShuHeiTi-Bold.woff") format("woff");
  font-display: swap;
}
html {
  height: 100vh;
  overflow: hidden;
}

body {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #a1e2e2;
}

html,
body,
div,
p,
ul,
li {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}
.messageIndex {
  z-index: 9999 !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
}

.p_relative {
  position: relative;
}

.postion_left {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
}

.flex {
  display: flex;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-baseline {
  align-self: baseline;
}

.self-stretch {
  align-self: stretch;
}

.over1 {
  overflow: hidden;
  /*文本超出隐藏*/
  text-overflow: ellipsis;
  /*文本超出显示省略号*/
  white-space: nowrap;
  /*超出的空白区域不换行*/
}

.over2 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-c {
  text-align: center;
}


.max-w {
  max-width: 750px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  margin: 0 auto;
}

@media (max-width:751px) {
  body {
    background-color: #fff;
  }
  .max-w {
    padding: 0 30rem;
  }
}

.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-l-c {
  display: flex;
  align-items: center;
}

::-webkit-scrollbar {

  width: 2px;

  height: 2px;

  background-color: rgba(245, 245, 245, .3);

}

/*定义滚动条轨道

内阴影+圆角*/

::-webkit-scrollbar-track {

  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);

  border-radius: 3px;

  background-color: rgba(233, 233, 233, .8);

}

/*定义滑块

内阴影+圆角*/

::-webkit-scrollbar-thumb {

  border-radius: 3px;

  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);

  background-color: rgba(85, 85, 85, .3);

}

.el-empty__image {
  width: 50%;
  max-width: var(--el-empty-image-width);
}

.fuwenben {
  img {
    max-width: 100%;
  }

}

.fade-enter-active {
  transition: opacity 1.5s;
}

.fade-leave-active {
  transition: opacity 0.05s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.treeListCol {
  .el-collapse-item__header:hover {
    background: #fff;
  }

  .el-collapse-item__content {
    padding-bottom: 0;
  }


}

.treeItemCol {
  .el-collapse-item:last-child {
    .el-collapse-item__wrap {
      border-bottom: none;
    }

  }
}

</style>
