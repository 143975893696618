<template>
  <div class="paper" ref="paper">
    <div class="progress_box">
      <span style="width: 130rem;">{{on}}/{{total}} 题</span>
      <van-progress :percentage="progressNum" :show-pivot="false" color="#6dc1c1" />
      <span style="width: 80rem;">{{displayTime}}</span>
      <img class="icon" src="../assets/16.png" alt="">
    </div>
    <div class="paper_list" ref="paper_list" :style="'height: '+ paperListHeight +'px'">
      <div class="loading_box" v-show="loading">
        <div class="rectangle_box">
          <div class="rectangle" v-for="item in 30" :key="item"></div>
        </div>
        <div class="eyes_1 eyes"></div>
        <div class="eyes_2 eyes"></div>
      </div>
      <div class="box" :style="'height: '+ paperListHeight+'px'" v-for="(item, index) in list" :key="index" :ref="'item'+index" :id="'item'+index">
        <div class="item">
          <div class="item_label">
            <span class="index">{{item.sort}}.</span>{{item.title}}
            <!-- <span class="question">{{item.title}}</span> -->
            </div>
          <img width="100%" v-if="item.icon" :src="item.icon" alt="" @load="handleImageLoad">
          <van-radio-group v-model="item.questionsInfo" shape="square">
            <van-radio label-position="left" :name="k.title" :label="k.content" v-for="(k, i) in item.questionsInfoDOList" :key="i" v-show="!checkList.includes(k.title)" :class="item.questionsInfo == k.title && 'on'" @click="radioChange(item, 'item'+index)">
              <span>{{k.title}}</span>
              <span v-if="k.content">.{{k.content}}</span>
            </van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="footer">
      <img class="left" src="../assets/8.png" @click="throttlePrevious">
      <img class="stop" src="../assets/9.png" @click="stop">
      <img class="right" src="../assets/10.png" @click="throttleNext">
    </div>

    <van-dialog v-model:show="show" :showConfirmButton="false">
      <img class="stop" src="../assets/18.png" alt="" style="margin: 80rem;">
      <div class="p">恭喜您！</div>
      <div class="str" style="matgin-top: 10rem;">您已经完成了所有的题目</div>
    </van-dialog>

    <van-dialog v-model:show="show2" title="您尚有答题未完成" :showConfirmButton="false">
      <div class="str">您已经参与测评且尚未完成，是否需要继续答题，或重新开始。</div>
      <div class="btn_box">
        <img src="../assets/14.png" alt="" style="margin-right: 30rem;">
        <img src="../assets/15.png" alt="">
      </div>
    </van-dialog>

    <van-dialog v-model:show="showStop" title="答题暂停" :showConfirmButton="false">
      <img class="stop" src="../assets/17.png" alt="">
      <div class="str">转转脖子歇一歇，保持轻松与轻快的答题节奏，休息结束后随时开始答题</div>
      <img class="btn" src="../assets/13.png" alt="" @click="startTimer">
    </van-dialog>


  </div>
</template>

<script>
import { 
  getQuestionsPage,
  evaluationValueCreate,
  evaluationLink,
  getNewquestions,
  evaluationValueRun,
  evaluationValuetover,
  evaluationValueOver
  } from '@/api'
import { showToast } from 'vant';
import { throttle, isEmpty, debounce } from 'lodash';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
export default {
  name: 'test-paper',
  data() {
    return {
      loading: false,
      paperStyle: 'paper_2',
      rem: 1,
      progressNum: 0,
      paperListHeight: 0,
      on: 0,
      list: [],
      total: 0,
      show: false,
      show2: false,
      showStop: false,
      count: 0,
      displayTime: '00:00',
      intervalId: null,
      stageList: [],
      stageNum: 0,
      stageName: '',
      steps: [],
      time: 0, // 本阶段限时
      type: 1,
      checkList: [],
      evaluationId: '',
      oldData: {}
    }
  },
  created() {
    this.stageList = JSON.parse(localStorage.getItem('stageList'))
    this.stageNum = localStorage.getItem('stageNum') || 0
    this.evaluationId = localStorage.getItem('evaluationId')
    if (!localStorage.getItem('userId')) {
      this.$router.push('/login')
    }
  },
  beforeUnmount () {
    this.stopTimer();
  },
  mounted() {
    this.getNew()
  },
  methods: {
    getNew () {
      this.loading = true
      getNewquestions({
        evaluationId: this.evaluationId, 
        userId: localStorage.getItem('userId'),
        typeId: localStorage.getItem('typeId'),
        }).then(res => {
        this.oldData = res.data || {}
        if (!isEmpty(this.oldData) && !isEmpty(this.oldData.answerStatus)) {
          this.stageNum = this.stageList.findIndex(k => {
            return k.id == this.oldData.subjectId
          })
          if (this.stageList[this.stageNum].type!==4) {
            this.stageNum++
          }
          localStorage.setItem('stageNum', this.stageNum)
        }
        this.getstage()
      })
    },
    init () {
      this.on = 0
      this.list = []
      this.show = false
      clearInterval(this.intervalId);
      this.getList()
    },
    getstage () {
      this.subjectId = this.stageList[this.stageNum].id
      this.time = this.stageList[this.stageNum].time
      this.type = this.stageList[this.stageNum].type
      this.paperStyle = this.type==3 ? 'paper_2' : ''
      this.stageName = this.stageList[this.stageNum].title
      document.title = this.stageName
      if (this.type != 4) {
        this.$router.push('/testPaper')
      }
      this.$nextTick(() => {
        this.init()
        if (this.stageList[this.stageNum].sortContent) {
          this.steps = [
            {
                element: '#item0',
                popover: {
                  description:  this.stageList[this.stageNum].sortContent,
                  side: 'center'
                }
            }
          ]
        }
      })
    },
    guide() {
      const driverObj = driver({
        steps: this.steps
      });
      driverObj.drive();
    },
    getList () {
      const data = {
        pageNo: 1,
        // pageSize: 999,
        subjectId: this.subjectId,
        evaluationId: this.evaluationId,
        userId: localStorage.getItem('userId')
      }
      getQuestionsPage(data).then(res => {
        this.list = res.data.list
        this.total = res.data.total
        this.$nextTick(_ => {
          this.getDomHeight()
          this.startTimer();
          if (!isEmpty(this.oldData) && !isEmpty(this.oldData.answerStatus)) {
            const item = this.oldData.answerStatus
            this.count = Number(this.oldData.content)
            const obj = this.list.find(k => {
              return k.id == item.questionsId
            })
            this.on = obj.number
          }
        })
      })
    },
    debounceStageChange: debounce(function() {
      this.stageChange();
    }, 500),
    stageChange () {
      this.stageNum++
      // this.init()
      if (isEmpty(this.stageList[this.stageNum])) {
        this.$router.push('/reportView')
        return
      }
      localStorage.setItem('stageNum', this.stageNum)
      // this.stageName = this.stageList[this.stageNum]
      this.subjectId = this.stageList[this.stageNum].id
      this.$router.push('/reportView')
      // this.show = true
    },
    throttlePrevious: throttle(function() {
      this.checkList.pop();
      this.previous();
    }, 1000),
    previous () {
      if (this.on == 1) return
      const previousDomm = this.$refs['item' + (this.on-1)][0];
      previousDomm.style.transform = 'translateY(0)';
      this.on = this.on-2
      this.$nextTick(() => {
        this.next()
      })
    },
    stop () {
      this.stopTimer()
    },
    throttleNext: throttle(function() {
      const obj = this.list[this.on-1]
      this.checkList.push(obj.questionsInfo)
      this.isNext();
    }, 1000),
    next: debounce(function() {
      this.loading = false
      this.debounceNext();
    }, 500),
    debounceNext () {
      if (this.list.length && this.on >= this.list.length) {
        // this.show = true
        this.$router.push('/reportView')
        evaluationValueOver({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')})
        clearInterval(this.intervalId);
        this.intervalId = null;
        return
      }
      for (let i = 0; i < this.on; i++) {
        const previousDomm = this.$refs['item' + i][0];
        previousDomm.style.transform = 'translateY(0)';
      }
      setTimeout(() => {
        const dom = this.$refs['item' + this.on][0];
        dom.style.transform = 'translateY(-' + (this.paperListHeight + (140*this.rem)) + 'px)';
        this.on++
      }, 200)
    },
    getDomHeight() {
      this.rem = document.documentElement.style.fontSize.slice(0, -2)
      this.paperListHeight = window.innerHeight - (265)*this.rem
      this.list.forEach((item, index) => {
        item.sort = (index+1) < 10 ? '0' + (index+1) : (index+1).toString();
      })
      let imgList = []
      this.$nextTick(() => {
        this.list.forEach((item, index) => {
          imgList.push(item.icon)
          const refName = `item${index}`;
          const itemElement = this.$refs[refName];
          if (itemElement) {
            const height = itemElement[0].offsetHeight; // 获取高度
            item.domHeight = height;
            itemElement[0].style.top = this.paperListHeight + 140 * this.rem + 'px';
          }
        });
        imgList = imgList.filter(Boolean);
        console.log(imgList)
        if (!imgList.length) {
          setTimeout(() => {
            this.next()
          }, 500)
        }
      });
    },
    handleImageLoad () {
      console.log('有图片加载完成 重新计算高度')
      this.$nextTick(() => {
        this.list.forEach((item, index) => {
          const refName = `item${index}`;
          const itemElement = this.$refs[refName];
          itemElement[0].style.top = this.paperListHeight + 140 * this.rem + 'px';
          if (itemElement) {
            const height = itemElement[0].offsetHeight; // 获取高度
            item.domHeight = height;
          }
        });
        // if (this.on == 1 || this.on == 2) {
          // this.on = 0
          setTimeout(() => {
            this.next()
          }, 1000)
        // }
      });
    },
    radioChange (row, ref) {
      let transformValue = this.$refs[ref][0].style.transform;
      let matches = transformValue.match(/translateY\((-?\d+)px\)/);
      if (matches && parseInt(matches[1], 10) === 0) {
        console.log("translateY的值为0"); 
        return
      }
      let data = {
        userId: localStorage.getItem('userId'),
        evaluationId: this.evaluationId, //活动id
        questionsId: row.id, // 题目id
        subjectId: row.subjectId, // 阶段id
        number: row.number,
        content: row.questionsInfo,
        status: 0,
        type: 1,
      }
      this.checkList.push(row.questionsInfo)
      evaluationValueCreate(data).then(res => {
        this.next()
      })
    },
    isNext (i) {
      const index = i || this.on
      const item = this.list[index-1]
      if (item.questionsInfo) {
        this.next()
      } else if(!i) {
        showToast('当前页面还有题目未完成哦!');
      }
    },
    startTimer() {
      evaluationValueRun({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')}).then(res => {
        this.count = Number(res.data.time)
        this.showStop = false
        this.intervalId = setInterval(() => {
          this.count++;
          let minutes = Math.floor(this.count / 60);
          let seconds = this.count % 60;
          this.displayTime = (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
          if (this.count % 5 === 0) {
            evaluationLink({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')})
          }
        }, 1000);
      })
    },
    stopTimer() {
      if (this.intervalId) {
        evaluationValuetover({subjectId: this.subjectId,evaluationId: this.evaluationId,userId: localStorage.getItem('userId')})
        clearInterval(this.intervalId);
        this.intervalId = null;
        this.showStop = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
.paper {
  width: 750rem;
  height: calc(100vh - 140rem);
  background: #edf6f6;
  padding-bottom: 140rem;
  overflow: hidden;
}
.title {
  color: #333333;
  font-weight: 600;
  font-size: 32rem;
  text-align: center;
  line-height: 64rem;
  padding-top: 40rem;
  position: relative;
  .goleft {
    position: absolute;
    top: 40rem;
    left: 40rem;
    width: 64rem;
    height: 64rem;
  }
}
.progress_box {
  display: flex;
  align-items: center;
  padding: 0 30rem;
  margin: 40rem 0 50rem;
  span {
    display: inline-block;
    height: 32rem;
    color: #000000;
    font-size: 32rem;
    line-height: 32rem;
  }
  .van-progress {
    flex: 1;
    flex-shrink: 0;
    height: 12rem;
    border-radius: 6rem;
    background: #c7e7e7;
    margin: 0 26rem;
  }
  .icon {
    width: 25.5rem;
    height: 25.5rem;
    margin-left: 10rem;
  }
}
.paper_list {
  // background-color: #fff;
  position: relative;
  overflow: hidden;
  .box {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    width: 750rem;
    transform: translateY(0);
    transition: transform 1s ease;
    border-bottom: 20rem solid #edf6f6;
  }
  .item {
    width: 710rem;
    background: #ffffff;
    padding: 56rem 40rem 0;
    margin: 40rem 20rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(0);
    transition: transform 1s ease;
    .item_label {
      margin-bottom: 54rem;
      font-size: 32rem;
      line-height: 42rem;
      .index {
        color: #333333;
        font-family: 'SourceHanSerifCN';
        font-size: 56rem;
        line-height: 56rem;
        margin-right: 10rem;
      }
      .question {
        padding-top: 15rem;
        color: #333333;
        font-size: 32rem;
        line-height: 42rem;
        white-space: pre-wrap;
      }
    }
    .van-radio-group {
      display: flex;
      .van-radio {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        margin-bottom: 60rem;
        color: #444444;

        font-weight: 300;
        font-size: 32rem;
        line-height: 48rem;


        width: 80rem;
        height: 80rem;
        border-radius: 20rem;
        border: 2rem solid rgba(0, 0, 0, 1);
        box-sizing: border-box;
        background: rgba(255, 255, 255, 1);
        margin-right: 30rem;
        ::v-deep .van-radio__icon {
          display: none;
        }
        ::v-deep .van-radio__label--left {
          margin: 0;
        }
        ::v-deep .van-radio__icon--checked .van-icon {
          color: #c2e5e5;
          background-color: #6dc1c1;
          border-color: #6dc1c1;
        }
      }
      .on {
        border: 2rem solid rgba(109, 193, 193, 1);
        ::v-deep .van-radio__label {
          color: #6DC1C1;
        }
      }
      ::v-deep .van-radio__icon .van-icon {
        width: 24rem;
        height: 24rem;
        line-height: 24rem;
        font-size: .6em;
      }
    }
  }
  
}
.footer {
  position: absolute;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 750rem;
  height: 140rem;
  border-radius: 40rem 40rem 0rem 0rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  .left {
    width: 60rem;
    height: 60rem;
  }
  .stop {
    width: 100rem;
    height: 100rem;
    margin: 0 60rem;
  }
  .right {
    width: 60rem;
    height: 60rem;
  }
}

::v-deep .van-dialog {
  width: 560rem;
  border-radius: 20rem;
  background: #ffffff;
  padding: 0 50rem 60rem;
  text-align: center;
  .str {
    color: #666666;

    font-size: 28rem;
    line-height: 48rem;
    margin-top: 30rem;
  }
  .step {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50rem 20rem 25rem;
    .icon_box {
      width: 80rem;
      height: 80rem;
      background: #edf6f6;
      border-radius: 80rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 44rem;
        height: 44rem;
      }
    }
    .on {
      background: #8acdcd;
      border: 2rem solid #6dc1c1;
    }
    .line {
      flex: 1;
      height: 4rem;
      border-radius: 4rem;
      background: #6dc1c1;
      margin: 0 10rem;
    }
  }
  .p {
    color: #333333;
    font-weight: 500;
    font-size: 32rem;
    line-height: 32rem;
  }
  .stop {
    margin-top: 50rem;
    width: 224rem;
    height: 236rem;
  }
  .btn {
    margin-top: 40rem;
    width: 160rem;
    height: 80rem;
  }
  .btn_box {
    margin-top: 40rem;
    img {
      width: 162rem;
      height: 82rem;
    }
  }
}


</style>