export const rawScore = {
  A:[3,26,27,51,52,76,101,126,151,176,],
  B:[28,53,54,77,78,102,103,127,128,152,153,177,178,],
  C:[4,5,29,30,55,79,80,104,105,129,130,154,179,],
  E:[6,7,31,32,56,57,81,106,131,155,156,180,181,],
  F:[8,33,58,82,83,107,108,132,133,157,158,182,183],
  G:[9,34,59,84,109,134,159,160,184,185],
  H:[10,35,36,60,61,85,86,110,111,135,136,161,186,],
  I:[11,12,37,62,87,112,137,138,162,163,],
  L:[13,38,63,64,88,89,113,114,139,164],
  M:[14,15,39,40,65,90,91,115,116,140,141,165,166],
  N:[16,17,41,42,66,67,92,117,142,167],
  O:[18,19,43,44,68,69,93,94,118,119,143,144,168,],
  Q1:[20,21,45,46,70,95,120,145,169,170],
  Q2:[22,47,71,72,96,97,121,122,146,171],
  Q3:[23,24,48,73,98,123,147,148,172,173],
  Q4:[25,49,50,74,75,99,100,124,125,149,150,174,175],
}


export const standardScore = {
  A: {
    1: '0-1',
    2: '2-3',
    3: '4-5',
    4: '6',
    5: '7-8',
    6: '9-11',
    7: '12-13',
    8: '14',
    9: '15-16',
    10: '17-20'
  },

  B: {
    1: '0-3',
    2: '4',
    3: '5',
    4: '6',
    5: '7',
    6: '8',
    7: '9',
    8: '10',
    9: '11',
    10: '12-13'
  },

  C: {
    1: '0-5',
    2: '6-7',
    3: '8-9',
    4: '10-11',
    5: '12-13',
    6: '14-16',
    7: '17-18',
    8: '19-20',
    9: '21-22',
    10: '23-26'
  },

  E: {
    1: '0-2',
    2: '3-4',
    3: '5',
    4: '6-7',
    5: '8-9',
    6: '10-12',
    7: '13-14',
    8: '15-16',
    9: '17-18',
    10: '19-26'},

  F: {
    1: '0-3',
    2: '4',
    3: '5-6',
    4: '7',
    5: '8-9',
    6: '10-12',
    7: '13-14',
    8: '15-16',
    9: '17-18',
    10: '19-26'},

  G: {
    1: '0-5',
    2: '6-7',
    3: '8-9',
    4: '10',
    5: '11-12',
    6: '13-14',
    7: '15-16',
    8: '17',
    9: '18',
    10: '19-20'},

  H: {
    1: '0-1',
    2: '2',
    3: '3',
    4: '4-6',
    5: '7-8',
    6: '9-11',
    7: '12-14',
    8: '15-16',
    9: '17-19',
    10: '20-26'},

  I: {
    1: '0-5',
    2: '6',
    3: '7-8',
    4: '9',
    5: '10-11',
    6: '12-13',
    7: '14',
    8: '15-16',
    9: '17',
    10: '18-19'},

  L: {
    1: '0-3',
    2: '4-5',
    3: '6',
    4: '7-8',
    5: '9-10',
    6: '11-12',
    7: '13',
    8: '14-15',
    9: '16',
    10: '17-20'},

  M: {
    1: '0-5',
    2: '6-7',
    3: '8-9',
    4: '10-11',
    5: '12-13',
    6: '14-15',
    7: '16-17',
    8: '18-19',
    9: '20',
    10: '21-26'},

  N: {
    1: '0-2',
    2: '3',
    3: '4',
    4: '5-6',
    5: '7-8',
    6: '9-10',
    7: '11',
    8: '12-13',
    9: '14',
    10: '15-20'},

  O: {
    1: '0-2',
    2: '3-4',
    3: '5-6',
    4: '7-8',
    5: '9-10',
    6: '11-12',
    7: '13-14',
    8: '15-16',
    9: '17-18',
    10: '19-26'},

  Q1: {
    1: '0-4',
    2: '5',
    3: '6-7',
    4: '8',
    5: '9-10',
    6: '11-12',
    7: '13',
    8: '14',
    9: '15',
    10: '16-20'},

  Q2: {
    1: '0-5',
    2: '6-7',
    3: '8',
    4: '9-10',
    5: '11-12',
    6: '13-14',
    7: '15',
    8: '16-17',
    9: '18',
    10: '19-20'
  },

  Q3: {
    1: '0-4',
    2: '5-6',
    3: '7-8',
    4: '9-10',
    5: '11-12',
    6: '13-14',
    7: '15',
    8: '16-17',
    9: '18',
    10: '19-20'
  },

  Q4: {
    1: '0-2',
    2: '3-4',
    3: '5-6',
    4: '7-8',
    5: '9-11',
    6: '12-14',
    7: '15-16',
    8: '17-19',
    9: '20-31',
    10: '22-26'
  },
}


//（1）适应与焦虑型X1：（标准1-10分）（原始-1.3~12.2分）
export function X1 (obj) {
  return [(38+2*obj.L+3*obj.O+4*obj.Q4)-(2*obj.C+2*obj.H+2*obj.Q2)]/10
}
//（2）内向与外向型X2：（得分1-10分）
export function X2 (obj) {
  return [(2*obj.A+3*obj.E+4*obj.F+5*obj.H)-(2*obj.Q2+11)]/10
}
// （3）感情用事与安详机警型X3：
export function X3 (obj) {
  return [(77+2*obj.C+2*obj.E+2*obj.F+2*obj.N)-(4*obj.A+6*obj.I+2*obj.M)]/10
}
// 怯懦与果断型X4：（得分1-10分）
export function X4 (obj) {
  return [(4*obj.E+3*obj.M+4*obj.Q1+4*obj.Q2)-(3*obj.A+2*obj.G)]/10
}
// 心理健康因素Y1：（得分4-40分）
export function Y1 (obj) {
  return obj.C+obj.F+(11-obj.O)+(11-obj.Q4)
}

// （6）专业有成就者的人格因素Y2：（得分10-100分）
export function Y2 (obj) {
  return obj.Q3*2+obj.G*2+obj.C*2+obj.E+obj.N+obj.Q2+obj.Q1
}
// 创造力强者的人格因素Y3：（得分5-150）

export function Y3 (obj) {
  return (11-obj.A)*2+obj.B*2+obj.E+(11-obj.F)*2+obj.H+obj.I*2+obj.M+(11-obj.N)+obj.Q1+obj.Q2*2
}

// 在新环境中有成长能力的人格因素Y4：（得分4-40分）
export function Y4 (obj) {
  return obj.B+obj.G+obj.Q3+(11-obj.F)
}

export const finalObj = {
  "A": {
    'title': '乐群性',
    "high": "高分数的特征以下统称高、：外向，热情，乐群。标准分高过8者，通常和蔼可亲，与人相处，合作与适应的能力特强。喜欢和别人共同工作，参加或组织各种社团活动，不斤斤计较，容易接受别人的批评。萍水相逢也可以一见如故。",
    "low": "低分数的特征以下统称低、：缄默，孤独，冷漠。标准分低于3者通常固执，对人冷漠，落落寡合，喜欢吹毛求疵，宁愿独自工作，对事而不对人，不轻易放弃自己的主见，为人做事的标准常常很高。严谨而不苟且。",
    "description": "教师和推销员多系高A，而物理学家和电机工程师则多系低A。前者需要时时应付人与人之间的复杂情绪或行为问题，而仍然能够保证其乐观的态度。后者则必须极端的冷静严肃与正确才能圆满地完成任务。"
  },
  "B": {
    'title': '智慧性',
    "high": "高：聪明，富有才识，善于抽象思考。高者通常学习能力强，思考敏捷正确，教育，文化水准高，个人心身状态健康。机警者多有高B，高B反映心理机能的正常。",
    "low": "低：思想迟钝，学识浅薄，抽象思考能力弱。低者通常对学习与了解能力不强，不能“举一隅而以三隅反”。迟钝的原因可能由于情绪不稳定，心理病态或失常所致。",
    "description": "专业训练需要高B，但从事例行职务的人如大资源，电话生，家庭主妇等，则因高B而对例行琐务发生厌恶，不能久安其职。"
  },
  "C": {
    'title': '稳定性',
    "high": "高：情绪稳定而成熟，能面对现实。高者通常以沉着的态度应付现实各项问题。行动充满魄力。能振奋勇气，维持团队的精神。有时高C也可能由于不能彻底解决许多生活难题，而不得不自我宽解。",
    "low": "低：情绪激动，易生烦恼。低者通常不能以“逆来顺受”的态度，应付生活上所遭遇的阻扰和挫折，容易受环境的支配，而心神动摇不定。不能面对现实，时时会暴躁不安，心身疲乏，甚至于失眠，噩梦，恐怖等症象。所有神经病人和精神病人都属低C..",
    "description": "教师，机器工程师，推销员，救火队队员等，凡需要应付日常生活各种难题者应有高C。但是凡能随心所欲，安排自己工作进度的人，如作家，邮差或清道工等，则虽系低C，尚无大碍。"
  },
  "E": {
    'title': '影响性',
    "high": "高：好强固执，独立积极。高者通常自视甚高，自以为是。可能非常地武断，而时常驾驭不及他的人和反抗权势者。",
    "low": "低：谦逊，顺从，通融，恭顺。低者通常行为温顺，迎合别人的意旨，也可能因为希望可遇而不可求，即使处在十全十美的境地，而有“事事不如人”之感，许多精神病人都有这样消极的心情。",
    "description": "一般的，领袖以及有地位有成就的人多属高E。救火队员和航空飞行员的因素E高。男人较女人高。"
  },
  "F": {
    'title': '活泼性',
    "high": "高：轻松兴奋，随遇而安。高者通常活泼，愉快，健谈，对人对事热心而富有感情。但是有时也可能会冲动，以致行为变化莫测。",
    "low": "低：严肃，谨慎，冷静，寡言。低者通常行动拘禁，内省而不轻易发言，较消极，忧郁。有时候可能过分深思熟虑，又近乎骄傲自满。在职责上，他常是认真而可靠的工作人员。",
    "description": "行政主管人员多有高F。竞选人必有高F,才能够获得选民的爱戴，实验技术人员则不必有高F。"
  },
  "G": {
    'title': '有恒性',
    "high": "高：持恒负责，做事尽职。高者通常细心周到，有始有终。是非善恶是他的行为指针。所结交的朋友多是努力苦干的人，而不十分欣赏诙谐有趣的人。",
    "low": "低：苟且敷衍，缺乏奉公守法的精神。低者通常缺乏较高的目标和理想，对于人群及社会没有绝对的责任感，甚至于有时不惜执法犯法，不择手段已达到某一目的。但他常能有效的解决实际问题，而无须浪费时间和精力。",
    "description": "各种社团组织的领袖需要高G。业务管理和警察具有极高的因素G。任性纵欲，放火杀人的罪犯，因素G 极低。"
  },
  "H": {
    'title': '交际性',
    "high": "高：冒险敢为，少有顾忌。高者通常不掩饰，不畏缩，有敢做敢为的精神，使他能经历艰辛而保持刚毅的一例。有时可能太粗心大意，忽视细节，遭受无谓的打击与挫折。可能无聊多事，喜欢向异性殷勤卖力。",
    "low": "低：畏怯退缩，缺乏自信心。低者通常在人群中羞怯。有不自然的姿态，有强烈的自卑感。拙于发言，更不愿和陌生人交谈。凡是采取观望的态度，有时由于过分的自我意识而忽视了社会环境中的重要事物与活动。",
    "description": "因素H常随年龄而增强。救火队队员和飞行员有高H，事物员多是低H。团队领导人必具有高H。"
  },
  "I": {
    'title': '情感性',
    "high": "高：敏感，感情用事。高者通常心肠软，易受感动，较女性化，爱好艺术，富于幻想。有时过分不切实际，缺乏耐心和恒心，不喜欢接近粗俗的人和做笨重的工作。在团体活动中，不着实际的看法与行为常常减低了团队的工作效率。",
    "low": "低：理智的，着重现实，自恃其力。低者唱常以客观，坚强，独立的态度处理当前的问题。重视文化修养，可能过分冷酷无情。",
    "description": "室内设计师，音乐家，艺人，女人属高I，而工程师，外科医生，统计师等则多低I."
  },
  "L": {
    'title': '怀疑性',
    "high": "高：怀疑，刚愎，固执己见。高者通常怀疑，不信任别人，与人相处，常常斤斤计较，不顾及到别人的利益。",
    "low": "低：信赖随和，易与人相处。低者通常无猜忌，不与人角逐竞争，顺应合作，善于体贴人。",
    "description": "在团体活动中，低L是以团体福利为前提的忠实分子，因素L过分高者常常成事不足，败事有余。工程师，机工，精神病护理员多是低L，而行政人员和警察常是高L."
  },
  "M": {
    'title': '想象性',
    "high": "高：幻想的，狂放不羁。高者通常忽视生活的细节，只以本身的动机，当时兴趣等主观因素为行为的出发点。可能富有创造力，有时也过分不务实际，近乎冲动。因而容易被人误解及奚落。",
    "low": "低：现实，合乎成规，力求妥善合力。低者通常先要斟酌现实条件，而后决定取舍，不鲁莽从事。在紧要关头时，也能保持镇静，有时可能过分重视现实，为人索然寡趣。",
    "description": "艺术家，作者及从事研究者多有高M。低M多选择需要实际，机警，脚踏实地的工作。"
  },
  "N": {
    'title': '世故性',
    "high": "高：精明能干，世故。高者通常处事老练，行为得体。能冷静地分析一切，近乎狡猾。对于一切事物的看法是理智的，客观的。",
    "low": "低：坦白，直率，天真。低者通常思想简单，感情用事。与人无争，与世无杵，自许，心满意足。但有时显得幼稚，粗鲁，笨拙，似乎缺乏教养。",
    "description": "科学家，工程师，飞行员多是高N，牧师神父，护士等多是低N，牧师的因素N不应太高，低N使他不苛求责难，能容忍同情信徒的缺点。"
  },
  "O": {
    'title': '忧虑性',
    "high": "高：忧虑抑郁，烦恼自扰。高者通常觉得世道艰辛，人生不如意事常八九，甚至沮丧悲观，时时有患得患失之感。自觉不容于人，也缺乏和人接近的勇气。各种神经病和精神病人都有高O。",
    "low": "低：乐群，沉着，有自信心。低者通常有信心，不轻易动摇，信任自己有应付问题的能力，有安全感，能适应世俗。有时因为缺乏同情，而引发别人的反感与恶意。",
    "description": "年老的女招待，低级办事员，以及终生碌碌未尽如意的作家，编辑人等有高O。职业运动员，电机工，救火队员，护士等多是低O。"
    },
  "Q1": {
    'title': '变革性',
    "high": "高：自由的，批评激进，不拘泥现实。高者通常喜欢考验一切现有的理论与实施，而予以新的评价，不轻易判断是非，企图了解较前卫的思想与行为。可能广见多闻。愿意充实自己的生活经验。",
    "low": "低：保守的，尊重传统观念与行为标准。低者通常无条件地接受社会中许多相沿已久而有权威性的见解，不愿常是探求新的境界。常常激烈的反对新思想以及一切新的变动。在政治与宗教信仰上，墨守成规，可能被称为老顽固或时代的落伍者。",
    "description": "行政主管人，前卫的政治家，科学家都必须具有高Q1，护士，牧师神父与许多为首高深教育的技工等则多有低Q1，神经病人的因素Q1也比较低。"
    },
  "Q2": {
    'title': '独立性',
    "high": "高：自立自强，当机立断。高者通常能够自作主张，独自完成自己的工作计划，不依赖别人，也不受社会舆论的约束，同样也无意控制或支配别人，不嫌恶人，但是也不需要别人的好感。",
    "low": "低：依赖，随群附和。低者通常宁欲与人共同工作，而不愿独立孤行。常常放弃个人的主见而附和取得别人的好感，需要团体的支持以维持其自信心，却并非真正的乐群者。",
    "description": "科学家，行政主管人等多有高Q2，低Q2者不能胜任需要随机应变能力的职务。"
    },
  "Q3": {
    'title': '自律性',
    "high": "高：知己知彼，自律谨严。高者通常言行一致，能够合理的支配自己的感情行动。为人处世，总能保持其自尊心，赢得别人的尊重，有时却不免太固执己见。",
    "low": "低：矛盾冲突，不顾答题。低者通常既不能克制自己，又不能尊重礼俗，更不愿考虑别人的需要，充满矛盾却无法解决。生活适应有问题者多低Q3..",
    "description": "高Q3者多具有领袖能力的才干，主管人，电机工和生产部门的成功也需要高Q3。"
    },
  "Q4": {
    'title': '紧张性',
    "high": "高：紧张闲扰，激动挣扎。高者通常缺乏耐心，心神不安，态度兴奋。时常感觉疲乏，又无法彻底摆脱以求宁静。在社群中，他对人与事都缺乏信心。每日生活战战兢兢，而不能自给。",
    "low": "低：心平气和，闲散宁静，低者通常知足常乐，保持内心的平衡。也可能过分疏懒，缺乏进取心。",
    "description": "未能在生活或职业中发挥本身才智潜能的人多具有高Q4，如餐店招待，家庭主妇，缺乏生活保障的作业等。"
    }
}

export const occObj = {
  "X1": {
    "title": "适应与焦虑型",
    "high": "高分特征：不一定有神经症，因为它可能是情境性的，但也可能有一些调节不良的情况，即对生活上所要求的和自己意欲达成的事情常感到不满意。高度的焦虑可能会使工作受到破坏和影响身体健康",
    "low": "低分特征：生活适应顺利，通常感到心满意足，能做到所期望的及自认为重要的事情。如分数极低，则可能对困难的工作缺乏毅力，有事事知难而退，不肯奋斗努力的倾向。",
    "description": '',
    "totalScore": 10,
    'scoreArr': [
      {
        "num": 0,
        "name": '适应',
        "width": "30%",
      },
      {
        "num": 3,
        "name": '偏适应',
        "width": "20%",
      },
      {
        "num": 5,
        "name": '均衡',
        "width": "20%",
      },
      {
        "num": 7,
        "name": '偏焦虑',
        "width": "20%",
      },
      {
        "num": 9,
        "name": '焦虑',
        "width": "10%",
      }
    ]
  },
  "X2": {
    "title": "内向与外向型",
    "high": "高分特征：外倾，开朗，善于交际，不受拘束，有利于从事贸易工作。 ",
    "low": "低分特征：内倾，趋于胆小，自足，在与别人接触中采取克制态度，有利于从事精细工作。这种类型无所谓利弊，主要取决于在哪种情况下采取这种态度。",
    "description": '',
    "totalScore": 10,
    'scoreArr': [
      {
        "num": 0,
        "name": '内向',
        "width": "30%",
      },
      {
        "num": 3,
        "name": '偏内向',
        "width": "20%",
      },
      {
        "num": 5,
        "name": '不明显',
        "width": "20%",
      },
      {
        "num": 7,
        "name": '偏外向',
        "width": "20%",
      },
      {
        "num": 9,
        "name": '外向',
        "width": "10%",
      }
    ]
  },
  "X3": {
    "title": "感情用事与安详机警型",
    "high": "高分特征：富有事业心，果断，刚毅，有进取精神，精力充沛，行动迅速，但常忽视生活上的细节，只对明显的事物注意，有时会考虑不周，不计后果，冒然行事。",
    "low": "低分特征：情感丰富而感到困扰不安，它可能是缺乏信心、颓丧的类型，对生活中的细节较为含蓄敏感，性格温和，讲究生活艺术，采取行动前再三思考，顾虑太多。",
    "description": '',
    "totalScore": 10,
    'scoreArr': [
      {
        "num": 0,
        "name": '感情用事',
        "width": "30%",
      },
      {
        "num": 3,
        "name": '偏感情',
        "width": "20%",
      },
      {
        "num": 5,
        "name": '均衡',
        "width": "20%",
      },
      {
        "num": 7,
        "name": '偏机警',
        "width": "20%",
      },
      {
        "num": 9,
        "name": '机警',
        "width": "10%",
      }
    ]
  },
  "X4": {
    "title": "怯懦与果断型",
    "high": "高分特征：果断，独立，露锋芒，有气魄，有攻击性的倾向，通常会主动地寻找可以施展这种行为的环境或机会，以充分表现自己的独创能力，并从中取得利益。",
    "low": "低分特征：依赖别人，纯洁，个性被动，受人驱使而不能独立，对支持他的人在行动上常适应其需求，为获取别人的欢心会事事迁就。",
    "description": '',
    "totalScore": 10,
    'scoreArr': [
      {
        "num": 0,
        "name": '怯懦型',
        "width": "30%",
      },
      {
        "num": 3,
        "name": '偏怯懦型',
        "width": "20%",
      },
      {
        "num": 5,
        "name": '不明显',
        "width": "20%",
      },
      {
        "num": 7,
        "name": '偏果断',
        "width": "20%",
      },
      {
        "num": 9,
        "name": '果断',
        "width": "10%",
      }
    ]
  },
  "Y1": {
    "title": "心理健康因素",
    "high": "",
    "low": "",
    "description": "心理健康状况几乎是一切职业及事业成功的基础。心理不健康者，其学习和工作效率都会因之减低。心理健康标准可介于4-40之间，均值为22分。低于12分者仅占人数分配的10%，情绪不稳定的程度颇为显著。",
    "totalScore": 40,
    'scoreArr': [
      {
        "num": 0,
        "name": '不稳定',
        "width": "30%",
      },
      {
        "num": 12,
        "name": '平均',
        "width": "33.33%",
      },
      {
        "num": 22,
        "name": '较稳定',
        "width": "33.33%",
      },
      {
        "num": 32,
        "name": '稳定',
        "width": "33.33%",
      }
    ]
  },
  "Y2": {
    "title": "专业有成就者的人格因素",
    "high": "",
    "low": "",
    "description": "本次级因素意指人格中的某些因素可能对将来的专业成就所具有的影响，它并不代表将来专业成就所达到的水平。其总分可介于10-100之间，平均分为55，67以上者其成功的机会更大。",
    "totalScore": 100,
    'scoreArr': [
      {
        "num": 0,
        "name": '低分',
        "width": "20%",
      },
      {
        "num": 20,
        "name": '较低',
        "width": "20%",
      },
      {
        "num": 40,
        "name": '中等',
        "width": "20%",
      },
      {
        "num": 60,
        "name": '较高',
        "width": "20%",
      },
      {
        "num": 80,
        "name": '高分',
        "width": "20%",
      }
    ]
  },
  "Y3": {
    "title": "创造力强者的人格因素",
    "high": "",
    "low": "",
    "description": "原始分数在15-150之间，越高创造性越强。标准分高于7（高于88）者属于创造力强者的范围，应有其成就。",
    "totalScore": 10,
    'scoreArr': [
      {
        "num": 0,
        "name": '低分',
        "width": "33.33%",
      },
      {
        "num": 3,
        "name": '中等',
        "width": "33.33%",
      },
      {
        "num": 8,
        "name": '高分',
        "width": "33.33%",
      }
    ]
  },
  "Y4": {
    "title": "在新环境中有成长能力的人格因素",
    "high": "",
    "low": "",
    "description": "本次级因素总分可介于4-40之间，平均值为22分，不足17分者仅占人数的10%左右，从事专业或训练成功的可能性较小。27分以上者，有成功的希望。27分之上者对工作适应能力很强。",
    "totalScore": 40,
    'scoreArr': [
      {
        "num": 0,
        "name": '较低',
        "width": "33.33%",
      },
      {
        "num": 16,
        "name": '中等',
        "width": "33.33%",
      },
      {
        "num": 26,
        "name": '较高',
        "width": "33.33%",
      }
    ]
  }
}