<template>
  <div>

  </div>
</template>

<script>
export default {
  name: 'empty_view',
  data() {
    return {

    }
  },
  created() {
    this.$router.push('/?evaluationId=67')
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>