import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from '../views/login.vue'
import ToolPage from '../views/toolPage.vue'
import ToolDetail from '../views/toolDetail.vue'
import TestPaper from '../views/testPaper.vue'
import TestPaperPic from '../views/testPaper_pic.vue'
import ReportView from '../views/reportView.vue'
import Cattell16PF from '../views/Cattell16PF/index.vue'
import Empty from '../views/empty.vue'
const routes = [
  {
    path: '/HomeView',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'toolPage',
    component: ToolPage,
  },
  {
    path: '/toolDetail',
    name: 'toolDetail',
    component: ToolDetail,
  },
  {
    path: '/testPaper',
    name: 'testPaper',
    component: TestPaper,
  },
  {
    path: '/testPaperPic',
    name: 'testPaperPic',
    component: TestPaperPic,
  },
  {
    path: '/reportView',
    name: 'reportView',
    component: ReportView,
  },
  {
    path: '/cattell16PF',
    name: 'cattell16PF',
    component: Cattell16PF,
    meta: { title: '卡特尔16PF测评报告' }
  },
  {
    path: '/Empty',
    name: 'Empty',
    component: Empty,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
})

export default router
