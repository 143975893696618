<template>
  <div class="report_view">
    <div class="card">
      <div class="title">测评结束</div>
      <div class="title">即将自动返回首页</div>
      <!-- <div class="text">
        <span>立马获取</span>
        <span>您的测评分析报告</span>
      </div>
      <div class="qr_box">
        <img class="pos" src="../assets/22.png" alt="">
        <div class="qr" >
          <img src="" alt="">
        </div>
      </div>
      <div class="text">— 长按保存二维码 —</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'report-view',
  data() {
    return {

    }
  },
  created() {
    setTimeout(() => {
      this.$router.push('/')
    }, 3000)
  },
  mounted() {

  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.report_view {
  width: 750rem;
  min-height: 100vh;
  border-radius: 20rem;
  background: #edf6f6;
  padding: 40rem;
}
.card {
  width: 670rem;
  height: 880rem;
  border-radius: 20rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60rem 60rem 300rem;
  .title {
    color: #6dc1c1;
    font-weight: 500;
    font-size: 36rem;
    line-height: 100rem;
    // line-height: 36rem;
  }
  .text {
    color: #6dc1c1;
    font-size: 28rem;
    line-height: 28rem;
    padding: 30rem 0;
  }
  .qr_box {
    width: 550rem;
    height: 600rem;
    background: linear-gradient(180deg, #fcffff 0%, #edf6f6 50.5%, #ffffff 100%);
    position: relative;
    padding: 60rem;;
    .pos {
      position: absolute;
      top: 20rem;
      left:  50%;
      transform: translate(-50%, -50%);
      width: 96rem;
      height: 64rem;
    }
    .qr {
      width: 430rem;
      height: 430rem;
      border-radius: 20rem;
      border: 10rem solid #e2f3f3;
      box-sizing: border-box;
      background: #ffffff;
      padding: 30rem;
      img {
        width: 350rem;
        height: 350rem;
        border: 2rem solid #f6f6f6;
        box-sizing: border-box;
      }
    }
  }
}
</style>