import { createApp } from 'vue'
import 'vant/lib/index.css';
import Vant from 'vant'

import App from './App.vue'
import router from './router'
import store from './store'

router.afterEach((to, from) => {
  document.body.scrollTop = 0;
})

const app = createApp(App)

app
  .use(Vant)
  .use(store)
  .use(router)
  .mount('#app')
