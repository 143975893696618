import axios from 'axios'
// import router from '../router/index'

import { showToast } from 'vant';
const service = axios.create({
  baseURL: '', // api的base_url
  timeout: 15000, // 请求超时时间
  headers: { ['tenant-id']: 1 },
  // headers: localStorage.getItem('token') ? { 'CMSENCODING': lang, 'Authorization': localStorage.getItem('token') } : { 'CMSENCODING': lang }
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    // console.log(config);
    localStorage.getItem('token')
      ? (config.headers.mark = localStorage.getItem('token'))
      : ''
    // console.log(config);
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// respone拦截器
service.interceptors.response.use(
  (response) => {
    // 返回判断
    const code = parseInt(response.data.code)
    var result = response.data
    // console.log('----------------', response.data)
    if (code === 0) {
      // 正常请求
      return result
    } else if (code === 400) {
      // Toast(result.data)
      return Promise.reject(result)
    } else if (code === 401) {
      showToast({
        message: '登录失效',
        type: 'warning',
      })
      // localStorage.removeItem('token')
      // localStorage.removeItem('userInfo')
      // router.push('/')
      return Promise.reject(result)
    } else {
      // Toast(result.msg ? result.msg : result.message)
      // 其他状态
      return Promise.reject(result)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default service
