<template>
  <div class="tool_page">
    <div class="tool_card" v-for="(item, index) in list" :key="index" @click="gotext(item)">
      <img class="pic" :src="item.icon" alt="">
      <div class="title">{{item.title}}</div>
      <div class="text">
        {{item.content}}
      </div>
      <div class="foot_box">
        <div class="time">
          <img class="icon" src="../assets/21.png" alt="">
          <span>{{item.retime}}min</span>
        </div>
        <img class="go_item" src="../assets/23.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { getSubjectRenwuList } from '@/api'
export default {
  name: 'tool-page',
  data() {
    return {
      list: [],
      evaluationId: ''
    }
  },
  created() {
    if (this.$route.query.evaluationId) {
      localStorage.setItem('evaluationId', this.$route.query.evaluationId) // 活动id
    }
  },
  mounted() {
    localStorage.removeItem('stageList')
    localStorage.removeItem('stageNum')
    localStorage.removeItem('typeId')
    this.evaluationId = localStorage.getItem('evaluationId')
    this.getData()
  },
  methods: {
    getData () {
      getSubjectRenwuList({pageNo: 1, pageSize: 99, pid: this.evaluationId}).then(res => {
          this.list = res.data.list
      })
    },
    gotext (item) {
      // localStorage.setItem('stageList', JSON.stringify(item.subjectList))
      // localStorage.setItem('typeId', item.id)
      this.$router.push('/toolDetail?id=' + item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.tool_page{
  width: 750rem;
  min-height: 100vh;
  background: #EDF6F6;
  padding: 40rem 30rem;
}
.tool_card {
  width: 690rem;
  border-radius: 20rem;
  border: 2rem solid #ffffff;
  box-sizing: border-box;
  background: linear-gradient(102deg, #EDF6F6 0%, #EEFFF9 32.5%, #EDF6F6 69%, #F6FFF0 100%);
  padding: 20rem;
  margin-bottom: 20rem;
  .pic {
    width: 650rem;
    height: 320rem;
    border-radius: 10rem 10rem 0rem 0rem;
    object-fit: cover;
  }
  .title {
    color: #333333;
    font-weight: 600;
    font-size: 36rem;
    line-height: 36rem;
    margin: 30rem 0 24rem;
    padding: 0 20rem;
  }
  .text {
    color: #666666;
    font-size: 28rem;
    line-height: 42rem;
    padding: 0 20rem;
  }
  .foot_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20rem;
    .time {
      display: flex;
      align-items: center;
      height: 60rem;
      border-radius: 20rem;
      background: #ffffff;
      color: #6dc1c1;
      font-size: 28rem;
      line-height: 28rem;
      .icon {
        width: 28rem;
        height: 28rem;
        margin: 16rem;
      }
      span {
        border-left: 2rem solid #6dc1c1;
        padding: 0 16rem;
      }
    }
    .go_item {
      width: 60rem;
      height: 60rem;
    }
  }
}
</style>