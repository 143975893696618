export function toChineseNum(num) {
  const chineseNums = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  const chineseUnits = ['', '十', '百', '千'];
  const chineseBigUnits = ['', '万', '亿', '兆'];

  let str = num.toString();
  let len = str.length;

  if (len === 1) {
    return chineseNums[num];
  }

  let result = '';
  let unitPos = 0; // 单位的位置

  while (num > 0) {
    let part = num % 10000; // 部分数字
    if (part !== 0) {
      let partStr = '';
      let counter = 0;
      let isTenToNineteen = false; // 标记是否是10到19

      while (part > 0) {
        let digit = part % 10;
        if (digit !== 0) {
          if (counter === 1 && (partStr === chineseNums[0] || partStr === '') && digit === 1) {
            // 特殊处理10到19的情况
            partStr = chineseUnits[counter];
            isTenToNineteen = true;
          } else {
            partStr = chineseNums[digit] + chineseUnits[counter] + partStr;
          }
        } else if (!partStr.startsWith(chineseNums[digit]) && !isTenToNineteen) {
          partStr = chineseNums[digit] + partStr;
        }
        part = Math.floor(part / 10);
        counter++;
      }

      result = partStr + chineseBigUnits[unitPos] + result;
    } else if (result.length > 0 && !result.startsWith(chineseNums[0])) {
      result = chineseNums[0] + result;
    }

    num = Math.floor(num / 10000);
    unitPos++;
  }

  return result;
}