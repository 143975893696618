import request from '../http/axios'


// 保存用户信息
export function saveUserInfo(query) {
  return request({
    url: '/app-api/user/info/save',
    method: 'post',
    data: query
  })
}

// #引导
export function getSubjectPage(query) {
  return request({
    url: '/app-api/subject/page',
    method: 'get',
    params: query,
  })
}

// 获得评测活动分页
export function getSubjectAnswerPage(query) {
  return request({
      url: '/app-api/SubjectAnswer/answer/page',
      method: 'get',
      params: query,
  })
}
// 测评工具列表
export function getSubjectRenwuList(query) {
  return request({
      url: '/app-api/subject/renwu/page',
      method: 'get',
      params: query,
  })
}
// 测评工具详情
export function getSubjectRenwuGet(query) {
  return request({
      url: '/app-api/subject/renwu/get',
      method: 'get',
      params: query,
  })
}


// 获得评测题目分页

export function getQuestionsPage(query) {
  return request({
      url: '/app-api/questions/page',
      method: 'get',
      params: query,
  })
}

//评测活动开始
export function evaluationValueRun(query) {
  return request({
      url: '/app-api/evaluation/value/run',
      method: 'get',
      params: query,
  })
}

//评测活动暂停
export function evaluationValuetover(query) {
  return request({
      url: '/app-api/evaluation/value/tover',
      method: 'get',
      params: query,
  })
}

//评测活动结束
export function evaluationValueOver(query) {
  return request({
      url: '/app-api/evaluation/value/over',
      method: 'get',
      params: query,
  })
}

// 创建评测记录(答题)
export function evaluationValueCreate(query) {
  return request({
      url: '/app-api/questions/value/create',
      method: 'post',
      data: query,
  })
}


// 创建评测记录(答题)
export function evaluationLink(query) {
  return request({
      url: '/app-api/evaluation/value/link',
      method: 'get',
      params: query,
  })
}

// 答题进度
export function getNewquestions(query) {
  return request({
      url: '/app-api/questions/log/getNew',
      method: 'get',
      params: query,
  })
}


// 获得评测记录
export function getResults(query) {
  return request({
    url: '/app-api/questions/value/getResults',
    method: 'get',
    params: query,
  })
}
