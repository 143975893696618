<template>
  <div class="login">
      <van-form ref="form" @submit="onSubmit">
        <van-field
          v-model="name"
          label-align="top"
          label="姓名："
          :rules="[
            { required: true, message: '请输入您的姓名' }
          ]"
          placeholder="请输入您的姓名"
        />
        <van-field name="radio" label="性别：" label-align="top">
          <template #input>
            <van-radio-group v-model="are" direction="horizontal">
              <van-radio name="男">男</van-radio>
              <van-radio name="女">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
          v-model="birthday"
          is-link
          readonly
          label-align="top"
          name="datePicker"
          label="出生日期"
          placeholder="选择出生日期"
          :rules="[
            { required: true, message: '请输入您的出生日期' }
          ]"
          @click="showPicker = true"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-date-picker
            v-model="nowDate"
            :min-date="minDate"
            :max-date="maxDate"
            :columns-type="columnsType"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <van-field
          v-model="phone"
          label-align="top"
          label="手机号："
          placeholder="请输入您的手机号"
          type="tel"
          :rules="[
          { required: true, message: '请输入您的手机号' }
          ]"
        />
      <div class="str">使用您的手机号和真实姓名完善信息</div>
        <div style="margin: 16px;text-align: center;">
          <img class="btn" src="../assets/15.png" alt="" @click="save">
        </div>
      </van-form>
  </div>
</template>

<script>
import { saveUserInfo, getSubjectPage, getSubjectAnswerPage } from '@/api'
export default {
  name: 'login-view',
  data() {
    return {
      showPicker: false,
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      columnsType: ['year', 'month', 'day'],
      nowDate: [],
      name: '',
      are: '',
      phone: '',
      birthday: '',
    }
  },
  created() {
    this.nowDate = [
      new Date().getUTCFullYear() - 20,
      new Date().getUTCMonth() + 1,
      new Date().getUTCDate()
    ]
  },
  mounted() {

  },
  methods: {
    save () {
      this.$refs.form.submit()
    },
    onSubmit () {
      const data = {
        name: this.name,
        are: this.are,
        phone: this.phone,
        birthday: this.birthday,
        status: 1,
        type: 1,
      }
      saveUserInfo(data).then(res => {
        localStorage.setItem('userId', res.data)
        this.$router.go(-1)
        // this.getSubjectPage()
      })
    },
    onConfirm ({ selectedValues }) {
      this.birthday = selectedValues.join('-');
      this.showPicker = false;
    },
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 750rem;
  height: 100vh;
  border-radius: 0;
  background: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  top: 0;
  transform: translateY(0);
  padding-top: 50rem;
  .title {
    color: #333333;
    font-weight: 500;
    font-size: 32rem;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  .str {
    text-align: center;
    color: #666666;
    font-size: 28rem;
    line-height: 28rem;
    margin: 50rem 0;
  }
  .van-cell {
    padding-right: 40rem;
    padding-left: 40rem;
  }
  .btn {
    width: 160rem;
    height: 80rem;
    margin: 20rem 0 40rem;
  }
}
::v-deep .van-radio__icon--checked .van-icon {
  color: #c2e5e5;
  background-color: #6dc1c1;
  border-color: #6dc1c1;
}
</style>